// Step 1: Import your component
import * as React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

import Container from "../components/Container"


const pageStyles = {
  color: "#232129",
  fontFamily: "Open Sans Condensed",
};

const Impressum = () => {
  return (
    <main style={pageStyles}>
      <title>Impressum</title>
      <Header />
      <Container>
        <h3>I M P R E S S U M </h3>
        <div>
          <p>
          Informationspflicht laut §5 ECG <br></br>
Offenlegungspflicht gem. §25 Mediengesetz:
          </p>
          <p>
          Silvija Podgorac<br></br>
          Praxisadresse: Hollgasse 6/3/R01, 1050 Wien<br></br>
Telefon: 0699/11827092<br></br>
Email: s.podgorac@me.com<br></br>
UID.Nr.: ATU77198701<br></br> <br></br>
Bildquellen: <br></br>
Unsplash, Pexels
          </p>
      </div>      
      </Container>
      <Footer />
    </main>
  );
};

// Step 3: Export your component
export default Impressum;
